import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const commonRoutes = [
    {
        path: '/login',
        name: 'login',
        meta: { title: '登录' },
        component: () => import('../components/Login.vue'),
    },
    {
        path: '/other', // 点击侧边栏跳到一个单独的路由页面，需要定义，层级和其他顶级路由一样
        name: 'other',
        meta: { title: '单独的路由' },
        component: () => import('../views/Other.vue'),
    },
    {
        path: '/404',
        name: '404',
        meta: { title: '404' },
        component: () => import('../components/404.vue'),
    },
    { path: '/', redirect: '/home' },
]

// 本地所有的页面 需要配合后台返回的数据生成页面
export const asyncRoutes = {
    home: {
        path: 'home',
        name: 'home',
        meta: { title: '主页' },
        component: () => import('../views/Home.vue'),
    },
	indexflooring: {
	    path: 'indexflooring',
	    name: 'indexflooring',
	    meta: { title: '柯诺地板' },
	    component: () => import('../views/kronospan/flooring.vue'),
	},
	indexupdataflooring: {
	    path: 'indexupdataflooring/:id(\\d+)',
	    name: 'indexupdataflooring',
	    meta: { title: '编辑' },
	    component: () => import('../views/kronospan/updataflooring.vue'),
	},
	future: {
	    path: 'future',
	    name: 'future',
	    meta: { title: 'kronospan未来' },
	    component: () => import('../views/kronospan/future.vue'),
	},
	number: {
	    path: 'number',
	    name: 'number',
	    meta: { title: '关于克诺斯邦-数据' },
	    component: () => import('../views/kronospan/number.vue'),
	},
	futureupdata: {
	    path: 'futureupdata/:id(\\d+)',
	    name: 'futureupdata',
	    meta: { title: '编辑' },
	    component: () => import('../views/kronospan/futureupdata.vue'),
	},
	banner: {
	    path: 'banner',
	    name: 'banner',
	    meta: { title: '轮播图' },
	    component: () => import('../views/kronospan/banner.vue'),
	},
	updatabanner: {
	    path: 'updatabanner/:id(\\d+)',
	    name: 'updatabanner',
	    meta: {title: '编辑'},
	    component: () => import('../views/kronospan/updatabanner.vue'),
	},
	addbanner: {
	    path: 'addbanner',
	    name: 'addbanner',
	    meta: { title: '新增轮播图' },
	    component: () => import('../views/kronospan/addbanner.vue'),
	},
	news: {
	    path: 'news',
	    name: 'news',
	    meta: { title: '新闻' },
	    component: () => import('../views/news/news.vue'),
	},
	addnews: {
	    path: 'addnews',
	    name: 'addnews',
	    meta: { title: '新增新闻' },
	    component: () => import('../views/news/addnews.vue'),
	},
	updatenews: {
	    path: 'updatenews/:id(\\d+)',
	    name: 'updatenews',
	    meta: {title: '编辑'},
	    component: () => import('../views/news/updatenews.vue'),
	},
    t1: {
        path: 't1',
        name: 't1',
        meta: { title: '表格' },
        component: () => import('../views/T1.vue'),
    },
	empower: {
	    path: 'empower',
	    name: 'empower',
	    meta: { title: '授权' },
	    component: () => import('../views/empower/empower.vue'),
	},
	updateempower: {
	    path: 'updateempower/:id(\\d+)',
	    name: 'updateempower',
	    meta: { title: '编辑' },
	    component: () => import('../views/empower/updateempower.vue'),
	},
	addempower: {
	    path: 'addempower',
	    name: 'addempower',
	    meta: { title: '新增授权' },
	    component: () => import('../views/empower/addempower.vue'),
	},
	product: {
	    path: 'product',
	    name: 'product',
	    meta: { title: '产品-木业' },
	    component: () => import('../views/product/product.vue'),
	},
	updataproduct: {
	    path: 'updataproduct/:id(\\d+)',
	    name: 'updataproduct',
	    meta: {title: '编辑'},
	    component: () => import('../views/product/updataproduct.vue'),
	},
	addproduct: {
	    path: 'addproduct',
	    name: 'addproduct',
	    meta: { title: '新增产品' },
	    component: () => import('../views/product/addproduct.vue'),
	},
	addtype: {
	    path: 'addtype',
	    name: 'addtype',
	    meta: { title: '用户信息' },
	    component: () => import('../views/product/addtype.vue'),
	},
	type: {
	    path: 'type',
	    name: 'type',
	    meta: { title: '分类管理' },
	    component: () => import('../views/product/type.vue'),
	},
	updatetype: {
	    path: 'updatetype/:id(\\d+)',
	    name: 'updatetype',
	    meta: { title: '编辑' },
	    component: () => import('../views/product/updatetype.vue'),
	},
	addtypeflooring: {
	    path: 'addtypeflooring',
	    name: 'addtypeflooring',
	    meta: { title: '用户信息' },
	    component: () => import('../views/productflooring/addtype.vue'),
	},
	typeflooring: {
	    path: 'typeflooring',
	    name: 'typeflooring',
	    meta: { title: '分类管理' },
	    component: () => import('../views/productflooring/type.vue'),
	},
	updatetypeflooring: {
	    path: 'updatetypeflooring/:id(\\d+)',
	    name: 'updatetypeflooring',
	    meta: { title: '编辑' },
	    component: () => import('../views/productflooring/updatetype.vue'),
	},
	productflooring: {
	    path: 'productflooring',
	    name: 'productflooring',
	    meta: { title: '产品-地板' },
	    component: () => import('../views/productflooring/product.vue'),
	},
	updataproductflooring: {
	    path: 'updataproductflooring/:id(\\d+)',
	    name: 'updataproductflooring',
	    meta: {title: '编辑'},
	    component: () => import('../views/productflooring/updataproduct.vue'),
	},
	addproductflooring: {
	    path: 'addproductflooring',
	    name: 'addproductflooring',
	    meta: { title: '新增产品' },
	    component: () => import('../views/productflooring/addproduct.vue'),
	},
	addtypeproductflooring: {
	    path: 'addtypeproductflooring',
	    name: 'addtypeproductflooring',
	    meta: { title: '用户信息' },
	    component: () => import('../views/productflooring/addtype.vue'),
	},
	typeproductflooring: {
	    path: 'typeproductflooring',
	    name: 'typeproductflooring',
	    meta: { title: '分类管理' },
	    component: () => import('../views/productflooring/type.vue'),
	},
	updatetypeproductflooring: {
	    path: 'updatetypeproductflooring/:id(\\d+)',
	    name: 'updatetypeproductflooring',
	    meta: { title: '编辑' },
	    component: () => import('../views/productflooring/updatetype.vue'),
	},
    password: {
        path: 'password',
        name: 'password',
        meta: { title: '修改密码' },
        component: () => import('../views/Password.vue'),
    },
    msg: {
        path: 'msg',
        name: 'msg',
        meta: { title: '通知消息' },
        component: () => import('../views/Msg.vue'),
    },
    userinfo: {
        path: 'userinfo',
        name: 'userinfo',
        meta: { title: '用户信息' },
        component: () => import('../views/UserInfo.vue'),
    }
}

const createRouter = () => new Router({
    routes: commonRoutes,
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router