export default {
	get(url, data) {
		return this.$axios.get(this.$ips + url,{params:data});
		// return new Promise((resolve, reject) => {
		// 	uni.request({
		// 		url: this.$ips + url,
		// 		method: 'GET',
		// 		data: data,
		// 		sslVerify: false,
		// 		success: res => {
		// 			resolve(res.data);
		// 		},
		// 		header: {
		// 			'Content-Type': 'application/x-www-form-urlencoded'
		// 		},
		// 		fail: (err) => {
		// 			console.log(err);
		// 			reject(err)
		// 		},
		// 		complete: () => {}
		// 	});
		// })
	},
	post(url, data) {
		return this.$axios.post(this.$ips + url,data);
		// return new Promise((resolve, reject) => {
		// 	uni.request({
		// 		url: this.$ips + url,
		// 		method: 'POST',
		// 		data: data,
		// 		sslVerify: false,
		// 		success: res => {
		// 			resolve(res.data);
		// 		},
		// 		header: {
		// 			'Content-Type': 'application/x-www-form-urlencoded'
		// 		},
		// 		fail: (err) => {
		// 			console.log(err);
		// 			reject(err)
		// 		},
		// 		complete: () => {}
		// 	});
		// })
	},
	gettime() {
		let date = new Date();
		let year = date.getFullYear();
		let month = (date.getMonth() + 1);
		let day = date.getDate();
		let hour = date.getHours();
		let minutes = date.getMinutes();
		let seconds = date.getSeconds();
		if (seconds < 10) {
			seconds = '0' + seconds
		}
		if (minutes < 10) {
			minutes = '0' + minutes
		}
		if (hour < 10) {
			hour = '0' + hour
		}
		if (day < 10) {
			day = '0' + day
		}
		if (month < 10) {
			month = '0' + month
		}
		return year + '-' + month + '-' + day + ' ' + hour +
			':' + minutes + ':' + seconds;
	},
	imgshow(url, arr) {
		url = this.$ips + '/common/file/' + url
		console.log(url);
		arr.map((item, index) => {
			arr[index] = this.$ips + '/common/file/' + item
		})
		wx.previewImage({
			current: url, // 当前显示图片的http链接
			urls: arr // 需要预览的图片http链接列表
		})
	},
	login() {
		uni.navigateTo({
			url: '../login/login',
			fail() {
				uni.navigateTo({
					url: '/pages/login/login',
					fail() {

					}
				});
			}
		});

	},
	selectfile(url) {
		return new Promise((resolve, reject) => {
			let that = this
			var urlfile = 'das'
			// const tempFilePaths = e.tempFilePaths;
			//将图片数据以二进制格式发送至服务器
			const uploadTask = uni.uploadFile({
				url: that.$ips + '/common/upfile',
				// filePath: tempFilePaths[0],
				filePath: url,
				name: 'file',
				success: function(uploadFileRes) {
					urlfile = uploadFileRes
					if (uploadFileRes.statusCode == 200) {
						resolve(JSON.parse(uploadFileRes.data).imgs_url)
						uni.showToast({
							title: '上传文件成功',
							icon: 'none'
						})

					}
				}
			});

		})
	},
}