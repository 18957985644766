import Vue from 'vue'
import axios from 'axios'
import ViewUI from 'view-design'
import App from './App'
import store from './store'
import router from './router'
import ElementUI,{Message} from 'element-ui';
// import {confirm} from 'element-ui';
import { MessageBox } from 'element-ui';
import request from './assets/common/request.js';
import 'element-ui/lib/theme-chalk/index.css';
import 'view-design/dist/styles/iview.css'
import './permission'

Vue.config.productionTip = false
Vue.prototype.$get = request.get;
Vue.prototype.$post = request.post;
Vue.prototype.$gettime = request.gettime;
// Vue.prototype.$ips = "http://localhost:3010";
Vue.prototype.$message = Message;
Vue.prototype.$confirm1 = MessageBox.alert;
// Vue.prototype.$ips = "http://47.96.3.97:3010";
Vue.prototype.$ips = "https://apiadmin.kronochina.com.cn";
Vue.prototype.$axios = axios

Vue.use(ViewUI)
Vue.use(ElementUI);
Vue.use(Message);
// Vue.use(confirm);
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
})